import { createNewSortInstance } from 'fast-sort'

const SORTING = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
}

const decideTheSortingOrder = (data, sortingOrder) => {
  // when no sorting order is set
  if (sortingOrder === null) return SORTING.ASCENDING

  // when sorting another column
  if (data.value !== sortingOrder.column) return SORTING.ASCENDING

  // switch between ascending and descending
  if (data.value === sortingOrder.column) {
    return sortingOrder.order === SORTING.ASCENDING ? SORTING.DESCENDING : SORTING.ASCENDING
  }
}

const applySortingOrder = (sortingOrder, sortedTableData) => {
  const naturalSort = createNewSortInstance({
    comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
  })

  if (sortingOrder.order === 'ASCENDING') {
    sortedTableData = naturalSort(sortedTableData).asc(sortingOrder.column)
  }
  if (sortingOrder.order === 'DESCENDING') {
    sortedTableData = naturalSort(sortedTableData).desc(sortingOrder.column)
  }

  return sortedTableData ?? []
}

export default (sorting, tableData) => {
  let sortingOrder = { order: sorting?.sorting ?? SORTING.ASCENDING, column: sorting?.value ?? '' }

  const order = decideTheSortingOrder(sorting, sortingOrder)
  const column = sorting.value

  const sortedResult = applySortingOrder({ order, column }, tableData)
  return { data: sortedResult, order: { order, column } }
}
